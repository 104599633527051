import React from 'react'
import Layout from '../components/layout'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from "gatsby"

const disclaimerPage = () => (
  <Layout>
    <div style={styles.divStyle}>
    <Link to="/">
    <p style={{color:"blue"}}>Go back to main page</p></Link>
   <article><h2>Disclaimer</h2>
    <p>This disclaimer governs your use of Fudo-an.com. By using this website, you accept this disclaimer in full. If you disagree with any part of this disclaimer, do not use Fudo-an.com or any affiliated websites, properties, or companies. We reserve the right to modify these terms at any time. You should therefore check back periodically for changes. By using this website after we post any changes, you agree to accept those changes, whether or not you have reviewed them.
    
    All information and resources found on Fudo-an.com are based on the opinions of the author unless otherwise noted. All information is intended to motivate readers to make their own nutrition and mental health decisions after consulting with their health care provider. I am not a doctor, lawyer, psychiatrist, therapist, or healer, nor do I intend to portray myself as one.
    
    The author of this site encourages you to consult a doctor before making any health changes, especially any changes related to a specific diagnosis or condition. No information on this site should be relied upon to determine diet, make a medical diagnosis, or determine treatment for a medical condition. The information on this website is not intended to replace a one-on-one relationship with a qualified health care professional and is not intended as medical advice.
    
    NO information on this site should be used to diagnose, treat, prevent or cure any disease or condition.
    </p>
    <h3>Full Disclaimer</h3>
    <p>By reading this website, you acknowledge that you are responsible for your own health decisions. Do not take anything from any website, including this one, and try it without proper research and medical supervision.
    
    Any statements or claims about the possible health benefits conferred by any foods or supplements have not been evaluated by the Food & Drug Administration (FDA) and are not intended to diagnose, treat, prevent or cure any disease.
    
    Fudo.com reserves the right to remove, edit, move or close any content item for any reason, including, but not limited to, comments that are in violation of the laws and regulations formed pursuant to the Federal Food, Drug and Cosmetic Act.
    
    None of the posts and articles on Fudo-an.com may be re-printed without express written permission of the author. Aislynn will respond to written requests to re-print parts of posts and excerpts/quotes (10% or less) may be reprinted with attribution as long as all links are left intact.
     </p>

    <h3>Copyright and Rights Reserved</h3>
    <p>
    All of the information on this website, including images, text, audio, video, and other forms of content (including the information on this disclosure page) is Copyright © Fudō-An Tea & Wellness Services 2018-2019 and may not be downloaded, reproduced, republished or otherwise copied without express written permission of the author and of Fudo-an.com.
    
    The Website and all of its Content and the selection and arrangement thereof, is protected as a compilation owned by Aislynn Creative, LLC and Fudo-an.com under the copyright laws of the United States and other countries.  Fudō-An Tea & Wellness Services may change the Website or delete any Content or features or services at any time, in any way, for any or no reason. Aislynn Creative, LLC reserves all rights not expressly granted in and to the Website and the Content.
    
    </p>
    </article>
    </div>
  </Layout>
)
var styles = {
  divStyle: {
    maxWidth:700
  }
};

export default disclaimerPage
